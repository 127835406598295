import React from "react";
import {Box, Button, Card, CardContent, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import dayjs from "dayjs";
import {Email, Sms, Telegram, WhatsApp} from "@mui/icons-material";
import {useParams} from "react-router-dom";

export const GuestCard = ({guest, mealData, userData}) => {
    const [notes, setNotes] = React.useState(guest.notes)
    const {tour_id} = useParams()

    const handleChangeNote = (e) => {
        setNotes(e.target.value)
    }

    function getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "windows phone";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios";
        }

        return "unknown";
    }

    const shareMethods = (guest_id) => {
        const qr = `https://user.sticket.app/${tour_id}/${guest_id}`
        let operatingSystem = getMobileOperatingSystem()
        let message = 'Al link che trovi di seguito puoi accedere al tuo QR code per ritirare i pasti in tour. %0a' +
            'Il QR sarà valido 1 volta a pasto.%0a'
        return ([
            {
                name: 'Whatsapp',
                icon: <WhatsApp/>,
                link: `whatsapp://send?text= ${message}${qr}`,
            },
            {
                name: 'Email',
                icon: <Email/>,
                link: `mailto:?Subject=QR code pasto&body=${message}${qr}`,
            },
            {
                name: 'Sms',
                icon: <Sms/>,
                link: `sms:${operatingSystem === 'ios' ? '&' : '?'}body=${message}${qr}`,
            }
        ])
    }
  return(
      <Card sx={{my: 2}}>
          <CardContent>
              <Typography textAlign={'center'}
                          color={(guest.user_meal_status['can_have_meal_now'] && guest.user_meal_status['meals_active_date']) ? "#91DCCC" : "#D96060"}
                          variant={'h4'} fontWeight={'bold'}>
                  {(guest.user_meal_status['can_have_meal_now'] && guest.user_meal_status['meals_active_date']) ? 'Valido' : 'Non valido'}
              </Typography>
              <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                  {
                      guest.user_meal_status['meals_active_date'] ?
                          `Pasto ${guest.user_meal_status['can_have_meal_now'] ? 'da consumare' : 'consumato'}`
                          :
                          'Il qr non è valido per questa data'
                  }

              </Typography>
              <Grid container sx={{py: 2}}>
                  <Grid item xs={6}>
                      <Typography>
                          Valido fino al:
                          <br/>
                          {dayjs(guest.date_ranges[0].end_date).format('DD/MM/YYYY')}
                      </Typography>
                  </Grid>
                  <Grid item xs={6}>
                      <Typography>
                          Pasti disponibili:
                          <br/>
                          {guest['num_meals_available']}/{guest['num_meals']}
                      </Typography>
                  </Grid>
              </Grid>
              <Typography textAlign={'center'} fontWeight={'bold'}>
                  Condividi il QR
              </Typography>
              <Stack direction={'row'} justifyContent={'space-around'}>
                  {shareMethods(guest.id).map((method, index) =>
                      <Box key={method.name+index}>
                          <IconButton href={method.link}>
                              {method.icon}
                          </IconButton>
                      </Box>
                  )}
              </Stack>
              <Box sx={{py:1}}>
                  <TextField fullWidth value={notes} onChange={handleChangeNote} label={'Note'} multiline minRows={2}/>
              </Box>
              <Box sx={{textAlign:'center'}}>
                  <Button variant={'contained'} disabled={notes === guest['notes']}>
                      salva
                  </Button>
              </Box>
          </CardContent>
      </Card>
  )
}
