import './App.css';
import {Route, Routes} from "react-router-dom";
import {User} from "./pages/User";
import {Box, CssBaseline} from "@mui/material";

function App() {
    return (
        <Box>
            <CssBaseline/>
            <Routes>
                <Route path="/" element={<Box/>}/>
                <Route path="/:tour_id/:id" element={<User/>}/>
            </Routes>
        </Box>
    );
}

export default App;
