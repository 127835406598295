import {createTheme, responsiveFontSizes} from "@mui/material";

export const sticketTheme = responsiveFontSizes(createTheme({
    components:{
      MuiButton:{
            styleOverrides:{
                root:{
                    borderRadius: '50rem',
                }
            }
      }
    },
    palette: {
        background:{
            default:"#F6F9F8",
        },
        primary: {
            main: '#070E2B',
        }
    }

}))
