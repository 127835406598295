import React, {useEffect} from "react";
import logo from "../logo.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import dayjs from 'dayjs';
import {sticketTheme} from "../theme/sticketTheme";
import DownloadIcon from '@mui/icons-material/Download';
import {ConfirmationNumber} from "@mui/icons-material";
import {TicketInfo} from "../components/TicketInfo";

export const User = () => {
    const {id, tour_id} = useParams()
    const [tourName, setTourName] = React.useState('')
    const [userData, setUserData] = React.useState(null)
    const [mealData, setMealData] = React.useState(null)
    const [userMealStatus, setUserMealStatus] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        await axios.get(`${API_URL}/tours/${tour_id}/users/${id}`)
            .then((response) => {
                setUserData(response.data.user)
                setMealData(response.data.meal_info)
                setUserMealStatus(response.data.user_meal_status)
                setTourName(response.data.tour_name)
            })
            .catch((error) => {
                setError(true)
            })
        setLoading(false)
    }

    return (
        <Box>
            <Box sx={{background: sticketTheme.palette.primary.main}}>
                <Container maxWidth={'xs'} sx={{textAlign: 'center'}}>
                    <Box sx={{pt: 1, pb: 3}}>
                        <img style={{width: 'min(10rem, 100%)', padding: '1rem', margin: 'auto'}}
                             src={require('../assets/images/logo-bi.png')}/>
                        {loading ? <CircularProgress/> :
                            <Typography color={'#F6F9F8'} variant={'h4'} fontWeight={'bold'}>
                                {userData?.name ?? ''} {userData?.surname ?? ''}
                            </Typography>}

                        {loading ? <CircularProgress/> :
                            <Typography color={'#F6F9F8'} variant={'body1'}>
                                {tourName ?? ''}
                            </Typography>}
                    </Box>
                </Container>
            </Box>
            <Container maxWidth={'xs'}>
                {loading ? <Box textAlign={'center'} sx={{pt: 8}}>
                        <CircularProgress/>
                    </Box> :
                    <TicketInfo userData={userData} mealData={mealData} userMealStatus={userMealStatus}/>
                }
            </Container>
        </Box>
    )
}
