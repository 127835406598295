import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {ConfirmationNumber} from "@mui/icons-material";
import {HandleGuests} from "./HandleGuests";
import dayjs from "dayjs";

export const TicketInfo = ({userData, mealData, userMealStatus}) => {
    const [handling, setHandling] = React.useState(false)

    const handleSetHandling = () => {
        setHandling(!handling)
    }

    function downloadImage(src) {
        const img = new Image();
        img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
        // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
        img.src = src;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            const a = document.createElement('a');
            a.download = 'download.png';
            a.href = canvas.toDataURL('image/png');
            a.click();
        };
    }

    return (
        <Box sx={{textAlign: 'center', py: 2}}>
            {!handling ?
                <Box>
                    <Typography textAlign={'center'}
                                color={(userMealStatus['can_have_meal_now'] && userMealStatus['meals_active_date']) ? "#91DCCC" : "#D96060"}
                                variant={'h4'} fontWeight={'bold'}>
                        {(userMealStatus['can_have_meal_now'] && userMealStatus['meals_active_date']) ? 'Valido' : 'Non valido'}
                    </Typography>
                    <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                        {
                            userMealStatus['meals_active_date'] ?
                                `Pasto ${userMealStatus['can_have_meal_now'] ? 'da consumare' : 'consumato'}`
                                :
                                'Il qr non è valido per questa data'
                        }

                    </Typography>
                    {userData.type === 'employee' ?
                        ((userMealStatus['can_have_meal_now'] && userMealStatus['meals_active_date']) &&
                            <Typography textAlign={'center'} sx={{pt: 1, pb: 2}}>
                                Mostra il QR al ristoratore
                                <br/>
                                per ricevere il tuo pasto
                            </Typography>
                            /*:
                            <Typography textAlign={'center'} sx={{pt: 1, pb: 2}}>
                                Il qr sarà di nuovo valido per il prossimo pasto
                            </Typography>*/)
                        :
                        <Grid container sx={{pb: 2, pt: 1}}>
                            <Grid item xs={6}>
                                <Typography>
                                    Valido fino al:
                                    <br/>
                                    {dayjs(userData.date_ranges[0].end_date).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    Pasti disponibili:
                                    <br/>
                                    {userData['num_meals_available']}/{userData['num_meals']}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {userData['qr_code_url'] &&
                        <img src={userData['qr_code_url']} style={{width: 'min(16rem, 100%)', margin: 'auto'}}/>}
                    <Box sx={{pt: 2}}>
                        <Button startIcon={<DownloadIcon/>} variant={'contained'}
                                onClick={() => downloadImage(userData['qr_code_url'])}>
                            Scarica il QR
                        </Button>
                    </Box>
                    {(userData.type === 'referent' && userData.guests.length > 0) && <Box sx={{pt: 2}}>
                        <Button startIcon={<ConfirmationNumber/>} variant={'contained'}
                                onClick={() => handleSetHandling()}>
                            Gestisci pass
                        </Button>
                    </Box>}
                </Box>
                :
                <HandleGuests userData={userData} handleSetHandling={handleSetHandling} mealData={mealData}/>
            }
        </Box>
    )
}
