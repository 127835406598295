import React from "react";
import {Box, Button, Card, CardContent, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {ArrowBack, Email, Sms, Telegram, WhatsApp} from "@mui/icons-material";
import dayjs from "dayjs";
import {GuestCard} from "./GuestCard";



export const HandleGuests = ({userData, handleSetHandling, mealData}) => {

    return (
        <Box sx={{textAlign: 'left'}}>
            <Button startIcon={<ArrowBack/>} onClick={handleSetHandling}>
                indietro
            </Button>
            {
                userData['guests'].map((guest, index) =>
                    <GuestCard key={index} mealData={mealData} guest={guest} userData={userData}/>
                )
            }
        </Box>
    )
}
